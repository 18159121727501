<template>
  <button
    class="button"
    :type="type === 'submit' ? type : 'button'"
    :class="[type ? '_type_' + type : '']"
    :disabled="disabled"
    :aria-disabled="disabled"
    @click="click"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    type: {
      type: String,
      default: "button",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click(event) {
      this.$emit("click", event);
    },
  },
};
</script>
